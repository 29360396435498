import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal, Button, Carousel } from "react-bootstrap";

export const Room = ({ room, fromdate, todate, guests }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="row bs">
        <div className="col-md-5">
          <img src={require("./hotel-img.jpg")} className="smallimg"></img>
        </div>

        <div className="col-md-7">
          <h1>{room.propertyInfo.name}</h1>
          <p> Description: {room.propertyInfo.desc}</p>

          <div style={{ float: "right" }}>
            <button className="btn btn-primary" onClick={handleShow}>
              See Availability
            </button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>Room Availability</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center mt-1">
            <div className="row room-type ">
              {room.availability?.map((roomtype) => {
                return (
                  <div className="row mt-2" key={"roomtype" + room.id}>
                    <hr />
                    <div className="col-md-3 justify-content-center">
                      <h1>
                       Room Type: <b>{roomtype.unitGroupCode}</b>
                      </h1>
                    </div>

                    <div className="row md-4 justify-content-center">
                      {roomtype.pricingOptions?.map((roomtypeoptions) => {
                        return (
                          <div
                            className="col-md-5 mt-2 ml-5 bs divspace"
                            key={"roomtypeoptions" + room.id}
                          >
                            <div className="row md-1">
                              <p><b>{roomtypeoptions.ratePlanCode}</b></p>
                              <p>Rate: {roomtypeoptions.currency} {roomtypeoptions.grossPrice}</p>
                            </div>

                            <div style={{ alignItems: "center" }}>
                              <Link
                                to={`/book/${room.propertyInfo.name}/${room.propertyInfo.code}/${roomtypeoptions.ratePlanCode}/${roomtype.unitGroupCode}/${fromdate}/${todate}/${guests}`}
                                state={{ roominfo: roomtypeoptions }}
                                style={{ marginLeft: 50 }}
                              >
                                {fromdate && todate && (
                                  <button className="btn btn-primary m-4">
                                    Book Now
                                  </button>
                                )}
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr style={{ marginTop: 30 }} />
                  </div>
                );
              })}
            </div>
          </div>

          <p>{room.desc}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Room;
