import React, { useState, useEffect, useRef } from "react";
import "./checkin.css";
import api1 from "../..";
import Spinner from 'react-bootstrap/Spinner';
import QrReader from 'react-qr-scanner';

const CheckIn = () => {
  const [bookingref, setBookingRef] = useState("");

  const [loading, setLoading] = useState(false);

  const [startCheckin, setStartCheckin] = useState(false);

  const [checkinresult, setCheckInResult] = useState("");

  const [checkindetails, setCheckInDetails] = useState({});
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");

  const headers = {};
  headers["Content-Type"]= "application/json";

 const CHECKIN = process.env.REACT_APP_API_CHECKIN;

  const handleScan = async (scanData) => {
    setLoadingScan(true);
    console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      if (scanData.hasOwnProperty("text")) {
        setData(scanData.text);
        setBookingRef(scanData.text);
        setStartScan(false);
        setLoadingScan(false);
        setStartCheckin(true);
      }

      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  const cameraConstraints = {
    video: true,
    facingMode: { exact: 'user' }
  };
  const previewStyle = {
    width: '300px',
    display: 'flex',
    "justify-content": "center"
  }

  useEffect(() => {
    if(startCheckin){
      checkIn();
    }
  },[startCheckin]);

  const checkIn = async () => {
    // when check in is pressed, set loading and reset the values until response recieved
    setCheckInDetails({});
    setCheckInResult("");

    if (bookingref === "") {
      alert("Please enter a valid booking reference");
    } else {
      const data = { bookingRefId: bookingref };
      // make call to api
      try {
        setLoading(true);
        const response = await api1.post(
          CHECKIN,
          data,
          {
            headers: headers
          }
        );

        setLoading(false);

        if (!response.data.hasOwnProperty("messages")) {
          setCheckInDetails(response.data.unitAllocation.unit);
          console.log(response.data.unitAllocation.unit);
        } else {
          setCheckInResult(response.data.messages[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="checkin-container">
      <div>
        <div className="row d-flex justify-content-around mt-5">
          <h1>Please enter your booking reference:</h1>
        </div>
        <div className="row mt-3">
          <input
            style={{ width: 450 }}
            onChange={(e) => setBookingRef(e.target.value.trim())}
            value={bookingref}
          ></input>
        </div>
        <div className="row mt-3 d-flex justify-content-around">
          <button
            className="checkInButton"
            onClick={() => checkIn()}
          >
            Check In
          </button>
          <button
            className="checkInQRButton"
            onClick={() => setStartScan(!startScan)}
          >
            {startScan ? "Stop Scan" : "Scan QR Code"}
          </button>
        </div>
        <div className="row d-flex justify-content-center mt-3">
          {startScan && (

            <QrReader
              constraints={cameraConstraints}
              delay={100}
              style={previewStyle}
              onError={handleError}
              onScan={handleScan}
            />

          )}
        </div  >
        {loading && (
          <div className="row d-flex justify-content-center mt-3">
            <Spinner animation="grow" />
          </div>
        )}
        {!loading && checkinresult !== "" && (
          <div>
            <span className="span-text">{checkinresult}</span>
          </div>
        )}
        {!loading && Object.keys(checkindetails).length !== 0 && (
          <div  >
            <div>
              <span className="span-text">Successfully Checked In!</span>
            </div>
            <div style={{ marginTop: 30}}>
              <h1>Room Details:</h1>
            </div>
            <div>
              <span className="span-text">
                Room Number: {checkindetails.name}
              </span>
            </div>
            <div>
              <span className="span-text">
                Description: {checkindetails.description}
              </span>
            </div>

            <div>
              <span className="span-text">Room ID: {checkindetails.id}</span>
            </div>

            <div>
              <span className="span-text">
                Room Group: {checkindetails.unitGroupId}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckIn;
