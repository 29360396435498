import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import api1 from "../..";
import "./bookingscreen.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

import { getRedirectUrl } from "../../util/redirect";
import { Modal, Button } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

function BookingScreen() {
  let {
    locationid,
    hotelid,
    rateplancode,
    unitgroupcode,
    fromdate,
    todate,
    guests,
  } = useParams();

  const hotelIdMap = {
    BER: "Berlin",
    LND: "London",
    MUC: "Munich",
    PAR: "Paris",
    VIE: "Vienna",
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [room, setRoom] = useState();

  const location = useLocation();

  const fromdate_c = moment(fromdate, "YYYY-MM-DD");
  const todate_c = moment(todate, "YYYY-MM-DD");

  // user entered info
  const [email, setEmail] = useState("");
  const [firstName, setfirstName] = useState("");
  const [secondName, setsecondName] = useState("");

  // payment session info
  const [paymentRef, setpaymentRef] = useState("");
  const [pspReference, setpspReference] = useState("");

  // booking information
  const [bookingDetails, setbookingDetails] = useState({});
  const [reservations, setReservations] = useState([]);

  const totaldays = moment.duration(todate_c.diff(fromdate_c)).asDays();

  const navigate = useNavigate();

  // MODAL CONTROLS
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const headers = {};
  headers["Content-Type"]= "application/json";

  const PAYMENT_SESSION = process.env.REACT_APP_API_PAYMENT_SESSION;
  const PAYMENT_PREAUTHORIZE = process.env.REACT_APP_API_PAYMENT_PREAUTHORIZE;
  
  const BOOKING = process.env.REACT_APP_API_BOOKING;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        setRoom(location.state.roominfo);

        setLoading(false);
      } catch (error) {
        setError(true);
        console.error(error.message);
        setLoading(false);
      }
    };

    fetchData();
    return;
  }, []);

  function handleBooking() {
    // check form inputs
    if (
      firstName.replace(/\s/g, "") === "" ||
      secondName.replace(/\s/g, "") === ""
    ) {
      alert("Please enter your First/Second name");
    } else if (email.replace(/\s+/g, "") === "") {
      alert("Please enter valid email");
    } else {
      // create session data
      // console.log(firstName + " " + secondName);
      setbookingDetails({
        name: firstName + " " + secondName,
        email: email,
      });

      // create reservations data
      let reservation = [];
      // If you want an inclusive end date (fully-closed interval)

      var singleBooking = {
        fromDate: fromdate,
        toDate: todate,
        adults: guests,
        channelCode: "Direct",
        guaranteeType: "Prepayment",
        propertyCode: hotelid.trim(),
        unitGroupCode: unitgroupcode.trim(),
        ratePlanCode: rateplancode.trim(),
        pmsId: "Apaleo",
      };

      // console.log(singleBooking);

      // add to reservations array

      reservation.push(singleBooking);

      setReservations(reservation);

      //console.log(bookingDetails);

      // create and pass session data through to payment

      handleShow();
    }
  }

  // payment gateway
  const Checkout = () => {
    const dispatch = useDispatch();
    const payment = useSelector((state) => state.payment);
    const navigate = useNavigate();
    const paymentContainer = useRef(null);
    const { type } = useParams();

    var reference = "";

    const sessionData = {
      booking: {
        booker: bookingDetails,
        reservations: reservations,
      },
      countryCode: "NL",
      amount: {
        currency: room.currency,
        value: room.grossPrice * 100,
      },
      channel: "Web",
      paymentGatewayId: "ADYEN",
    };

    //console.log(sessionData);
    // Calls your server endpoints
    async function callServer(url, data) {
      console.log(data);
      console.log(url);
      const res = await api1.post(url, 
        
        data,
        {
        //headers: headers
      });

      return await res.data;
    }

    useEffect(() => {
      const startCheckout = async () => {
        // Create AdyenCheckout using Sessions response

        try {
          console.log('session data', sessionData);
          const checkoutSessionResponse = await callServer(
            process.env.REACT_APP_API_PAYMENT_SESSION,
            sessionData
          );
          console.log('startcheckout' ,startCheckout);

          // Create AdyenCheckout using Sessions response
        console.log("session:", checkoutSessionResponse);
          console.log('checkoutsessionResponse' ,checkoutSessionResponse);
          reference = checkoutSessionResponse["reference"];
          console.log('reference' ,reference);
          console.log(checkoutSessionResponse);

          //console.log("reference:", reference);

          const checkout = await createAdyenCheckout(checkoutSessionResponse);
          console.log('console',checkout);

          // Create an instance of Drop-in and mount it
          // Maybe add loading before this
          checkout
            .create("card")
            .mount(document.getElementById("payment-container"));
        } catch (error) {
          console.error(error);
        }
      };

      startCheckout();
    }, []);

    async function preAuth(data) {
      const checkoutSessionResponse = await callServer(
        PAYMENT_PREAUTHORIZE,
        data
      );
      return await checkoutSessionResponse;
    }
    

    // Handles responses sent from your server to the client
    function handleServerResponse(res, component, bookref) {
      if (res.action) {
        component.handleAction(res.action);
      } else {
        switch (res.resultCode) {
          case "Authorised":
            window.location.href = `#/status/success/${bookref.id}`;
            break;
          case "Pending":
          case "Received":
            window.location.href = "#/status/pending";
            break;
          case "Refused":
            window.location.href = "#/status/failed";
            break;
          default:
            window.location.href = "#/status/error";
            break;
        }
      }
    }

    async function createAdyenCheckout(session) {
      const additionalData = {
        authorisationType: "PreAuth",
      };

      // create front-end payment session
      const configuration = {
        clientKey: "test_ZOXUB6RF5BHH5PE2YEKYAZBUV4MENCVP",
        locale: "en_US",
        environment: "test", // change to live for production
        showPayButton: true,
        session: session,
        paymentMethodsConfiguration: {
          ideal: {
            showImage: true,
          },
          card: {
            hasHolderName: false,
            holderNameRequired: false,
            name: "Credit or debit card",
            amount: {
              value: 0,
              currency: room.currency,
            },
          },
        },
        onSubmit: (state, component) => {
          if (state.isValid) {
          }
          // console.log(state);
          // console.log(component);

          var preAuthData = {
            reference: reference,
            amount: component.props.amount,
            paymentMethod: state.data.paymentMethod,
            additionalData: { authorisationType: "PreAuth" },
            paymentGatewayId: "ADYEN",
          };

          delete preAuthData.paymentMethod.taxNumber;

          // console.log(preAuthData);

          const returnData = preAuth(preAuthData);
          returnData.then(async function (response) {
            //create booking details
            const bookingInformation = {
              booker: bookingDetails,
              reservations: reservations,
              merchantReference: session["reference"],
              pspReference: response.pspReference,
            };
            
              
            const bookingresponse = await api1.post(
              BOOKING,
              bookingInformation,
              {
                headers: headers
              }
            );

            // console.log(response);
            // console.log(bookingresponse);

            // check response
            handleServerResponse(response, component, bookingresponse.data);
          });
        },
        beforeSubmit: (data, component, actions) => {
          additionalData = {
            authorisationType: "PreAuth",
          };
          data.additionalData = additionalData;
          actions.resolve(data);
        },
        onPaymentCompleted: (result, component) => {
          // console.log(result);
          // console.log(component);

          handleServerResponse(result, component);
        },
        onError: (error, component) => {
          console.error(error.name, error.message, error.stack, component);
        },
      };
      const checkout = new AdyenCheckout(configuration);
      console.log('checkout data',checkout);

      return checkout;
    }

    return (
      <div id="payment-container">
        <div ref={paymentContainer} className="payment"></div>
      </div>
    );
  };

  return (
    <div className="m-5">
      {loading ? (
        <Spinner animation="grow" />
      ) : error ? (
        <h1>error</h1>
      ) : (
        <div className="row justify-content-center mt-3">
          <div className="col-md-5">
            <h1>Booking for Hotel {locationid}</h1>
          </div>

          <div className="col-md-5">
            <h1>Booking details</h1>
            <hr />

            <div className="row mt-3">
              <span>Room Type: {unitgroupcode}</span>
            </div>

            <div className="row mt-3">
              <span>Rate Plan: {rateplancode}</span>
            </div>

            <div className="row mt-3">
              <span>Check-in: {fromdate}</span>
            </div>

            <div className="row mt-3">
              <span>Check-out: {todate}</span>
            </div>

            <div className="row mt-3 mb-3">
              <span>Total Nights: {totaldays}</span>
            </div>

            <div className="row mt-3 mb-3">
              <span>Total Guests: {guests}</span>
            </div>

            <h1>Enter your details</h1>
            <hr />

            <div class="form-group">
              <label for="firstname">First Name</label>
              <input
                type="text"
                class="form-control"
                id="firstname"
                placeholder="Enter first name"
                onChange={(e) => setfirstName(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="secondname">Second Name</label>
              <input
                type="text"
                class="form-control"
                id="secondname"
                placeholder="Enter second name"
                onChange={(e) => setsecondName(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="emailaddress">Email address</label>
              <input
                type="email"
                class="form-control"
                id="emailaddress"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <small id="emailHelp" class="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </div>

            <div class="form-group">
              <label for="phone">Phone Number</label>
              <input
                type="tel"
                class="form-control"
                id="phone"
                placeholder="Enter phone/mobile number"
              />
            </div>

            <div class="form-group">
              <label for="address">Address</label>
              <textarea
                class="form-control"
                id="address"
                rows="4"
                placeholder="Enter your address"
              ></textarea>
            </div>

            <div className="row mt-3">
              <h1>Cost summary</h1>
              <hr />

              <div>
                {room.priceBreakdown?.map((pricefornight, index) => {
                  return (
                    <p>
                      <b>Night {index + 1}:</b> {pricefornight.currency} {pricefornight.price}
                    </p>
                  );
                })}
              </div>

              <div>
                <span>Total Cost: {room.currency} {room.grossPrice}</span>
              </div>
            </div>

            <div className="row mt-3" style={{ float: "right" }}>
              <button className="confirmButton" onClick={handleBooking}>
                {" "}
                Confirm{" "}
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>{"Payment"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Checkout />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BookingScreen;
