import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./app/store";
import { Provider } from "react-redux";
import axios from "axios";

//let accessToken = null;
//await axios.post('https://api.dev.ciepocdev.com/token/').then((response) =>{
//accessToken =response.data.access_token;
//  });
const api1 = axios.create({});
//Disabling interceptor as we do not need to get token from frontend.
/*
api1.interceptors.request.use(
    (config) => {
    if(accessToken){
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
*/
   //};
  
//const redirectResponse=msalInstance.loginRedirect(loginApiRequest);
export default api1;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App/>
  </Provider>
);
reportWebVitals();

