import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { initiateCheckout } from "../../app/paymentSlice";
import "./payment.css";
import { getRedirectUrl } from "../../util/redirect";

function Payment() {
  return (
    
    <div className="container">
        <Checkout type={"card"} />
    
    </div>
    
  )
}

const Checkout = () => {
  const dispatch = useDispatch();
  const payment = useSelector(state => state.payment);

  const navigate = useNavigate();

  const paymentContainer = useRef(null);

  const { type } = useParams();

  useEffect(() => {
    dispatch(initiateCheckout(type));
  }, [dispatch, type])


  useEffect(() => {
    const { error } = payment;

    if (error) {
      navigate(`/status/error?reason=${error}`, { replace: true });
    }
  }, [payment, navigate])


  useEffect(() => {
    const { config, session } = payment;

    if (!session || !paymentContainer.current) {
      // initiateCheckout is not finished yet.
      return;
    }

    const createCheckout = async () => {

      console.log(config)
      
      const checkout = await AdyenCheckout({
        ...config,
        session,
        
        onPaymentCompleted: (response, _component) =>
          navigate(getRedirectUrl(response.resultCode), { replace: true }),
        onError: (error, _component) => {
          
          console.log("error");
          console.log(error.message)
          console.error(error);
          navigate(`/status/error?reason=${error.message}`, { replace: true });
        },
        
      });

      if (paymentContainer.current) {
        checkout.create('card').mount("#payment-container");
      }
    }

    createCheckout();
  }, [payment, type, navigate])

  return (
    <div id="payment-container">
      <div ref={paymentContainer} className="payment"></div>
    </div>
  );
}

export default Payment

