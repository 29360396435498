import React, { useState } from "react";
import { Link , useParams } from 'react-router-dom';
import { Modal, Button, Carousel } from "react-bootstrap";

export const Hotel = ({ room, index, fromdate, todate, guests}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Construct the image path
  const imagePath = `/images/hotels/hotel${index+1}.jpeg`;
  // Default image path
  const defaultImagePath = `/images/hotels/hotel1.jpeg`;

  // Function to handle image error
  const handleImageError = (e) => {
    e.target.src = defaultImagePath; // Set default image
  }

  const buttonDivStyle = {

    
    

  }

  return (
    <div>
      <div className="row bs" style={{alignItems:"center", width:600, justifyContent:"center"}}>
        <div className="col-md-5">
        <img
            src={imagePath}
            className="smallimg"
            alt={room.name}
            onError={handleImageError}
          />
        </div>

        <div className="col-md-7">
          <h1 style={{fontSize:30, fontWeight: "bold"}}>{room.name}</h1>
          
          

          <div style={buttonDivStyle}>

            
            
            <button className="btn btn-primary" onClick={handleShow}>
              View Details
            </button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header >
          <Modal.Title>{room.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel prevLabel='' nextLabel=''>
            
              <Carousel.Item>
                <img
                    className="d-block w-100 bigimg"
                    src={imagePath}
                    alt={room.name}
                    onError={handleImageError}
                  />
                  
              </Carousel.Item>
            
          </Carousel>

          <p>{room.desc}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Hotel;
