// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.navbar{
  background-color: black !important;
}

.navbar-toggler{
  color: rgb(0, 0, 0) !important;
  background-color: white !important;

}

.nav-link{
  color: white !important;
}

.navbar-brand{
  color: white !important;
  font-weight: bold !important;
}


.smallimg{
  height: 200px !important;
  width: auto;
  border-radius: 5px !important;
  
}

.bigimg{

  height: auto!important;
  width: auto;
  border-radius: 5px !important;

}

.divspace{
  margin-left: 30px;
}

img{
  max-width:100%;
  max-height:100%;
}

h1{
  font-size: 20px;
}

.btn{
  background-color: black;
  color: white;
  border-color: black;
  box-shadow: none;
  border-radius: 0px;
}

.col-md-7{
  margin-left: auto;
}

.bs{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 20px !important;
  border-radius: 5px !important;
  padding: 20px !important ;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;;;;AAKA;EACE,kCAAkC;AACpC;;AAEA;EACE,8BAA8B;EAC9B,kCAAkC;;AAEpC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,4BAA4B;AAC9B;;;AAGA;EACE,wBAAwB;EACxB,WAAW;EACX,6BAA6B;;AAE/B;;AAEA;;EAEE,sBAAsB;EACtB,WAAW;EACX,6BAA6B;;AAE/B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,4CAA4C;EAC5C,2BAA2B;EAC3B,6BAA6B;EAC7B,yBAAyB;AAC3B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n\n\n.navbar{\n  background-color: black !important;\n}\n\n.navbar-toggler{\n  color: rgb(0, 0, 0) !important;\n  background-color: white !important;\n\n}\n\n.nav-link{\n  color: white !important;\n}\n\n.navbar-brand{\n  color: white !important;\n  font-weight: bold !important;\n}\n\n\n.smallimg{\n  height: 200px !important;\n  width: auto;\n  border-radius: 5px !important;\n  \n}\n\n.bigimg{\n\n  height: auto!important;\n  width: auto;\n  border-radius: 5px !important;\n\n}\n\n.divspace{\n  margin-left: 30px;\n}\n\nimg{\n  max-width:100%;\n  max-height:100%;\n}\n\nh1{\n  font-size: 20px;\n}\n\n.btn{\n  background-color: black;\n  color: white;\n  border-color: black;\n  box-shadow: none;\n  border-radius: 0px;\n}\n\n.col-md-7{\n  margin-left: auto;\n}\n\n.bs{\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n  margin-top: 20px !important;\n  border-radius: 5px !important;\n  padding: 20px !important ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
