// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span{
    font-weight: bold;
}

.confirmButton{
    background-color: black;
    color: white;
    border-color: black;
    box-shadow: none;
    padding: 10px;
    width: auto;
}

.bookinginfo{

    width: auto;
    margin: 0px;
    display: flex;
    gap: 10px;
    

}

input{
    width: auto;
    margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/bookingscreen/bookingscreen.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,WAAW;AACf;;AAEA;;IAEI,WAAW;IACX,WAAW;IACX,aAAa;IACb,SAAS;;;AAGb;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["span{\n    font-weight: bold;\n}\n\n.confirmButton{\n    background-color: black;\n    color: white;\n    border-color: black;\n    box-shadow: none;\n    padding: 10px;\n    width: auto;\n}\n\n.bookinginfo{\n\n    width: auto;\n    margin: 0px;\n    display: flex;\n    gap: 10px;\n    \n\n}\n\ninput{\n    width: auto;\n    margin: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
