// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkInButton{
    background-color: black;
    color: white;
    border-color: black;
    box-shadow: none;
    padding: 10px;
    width: auto;
}

.checkInQRButton{
    background-color: black;
    color: white;
    border-color: black;
    box-shadow: none;
    padding: 10px;
    width: auto;
}

.span-text{
    font-weight: 300;
    font-size: large;
}

.checkin-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

`, "",{"version":3,"sources":["webpack://./src/pages/checkin/checkin.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".checkInButton{\n    background-color: black;\n    color: white;\n    border-color: black;\n    box-shadow: none;\n    padding: 10px;\n    width: auto;\n}\n\n.checkInQRButton{\n    background-color: black;\n    color: white;\n    border-color: black;\n    box-shadow: none;\n    padding: 10px;\n    width: auto;\n}\n\n.span-text{\n    font-weight: 300;\n    font-size: large;\n}\n\n.checkin-container{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 80vh;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
